import store from '@/store'
import {
    mdiAccountCheckOutline,
    mdiAccountOutline,
    mdiAccountPlusOutline,
    mdiAccountRemoveOutline,
    mdiCogOutline,
    mdiDatabaseOutline,
    mdiDnsOutline,
    mdiPencilOutline
} from '@mdi/js'
import {ref, watch} from '@vue/composition-api'

export default function templateList() {
    const headers = [
        {text: '模板名称', value: 'name'},
        {text: '模板内容', value: 'content'},
        {text: '操作', value: 'actions', align: 'center', sortable: false, width: 215}
    ]
    const param = ref({
        query: '',
        options: {sortBy: ['id'], sortDesc: [false], page: 1, itemsPerPage: 10}
    })
    const table = ref({
        list: [],
        total: 0,
        snack: {
            show: false,
            msg: ''
        },
        loading: false,
        typeList: [],
        dialogAddTemplate: {
            show: false,
            data: {id: "", type: "", name: "", content: ""}
        },
        dialogDelTemplate: {
            show: false,
            id: ""
        }
    })
    const fetchTemplateList = () => {
        table.value.loading = true
        store.dispatch('content-sms-template-list/fetchTemplateList', param.value).then(res => {
            const {code, msg} = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snack = {
                    show: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            const {list, total} = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => {
            console.log(error)
        }).finally(() => {
                table.value.dialogAddTemplate = {
                    show: false,
                    data: {id: ''}
                }
                table.value.loading = false
            }
        )
    }
    const fetchTemplateTypeList = () => {
        store.dispatch('content-sms-template-list/fetchTemplateTypeList', {}).then(res => {
            const {code, msg} = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snack = {
                    show: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            table.value.typeList = res.data.data || []
        }).catch(error => {
            console.log(error)
        }).finally()
    }
    const addTemplate = () => {
        table.value.loading = true
        const type = table.value.dialogAddTemplate.data.id === '' ? 'content-sms-template-list/addTemplate' : 'content-sms-template-list/editTemplate'
        store.dispatch(type, table.value.dialogAddTemplate.data).then(res => {
            const {code, msg} = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snack = {
                    show: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            table.value.dialogAddTemplate.show = false
            fetchTemplateList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    const delTemplate = () => {
        table.value.loading = true
        store.dispatch('content-sms-template-list/delTemplate', table.value.dialogDelTemplate).then(res => {
            const {code, msg} = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snack = {
                    show: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            table.value.dialogDelTemplate.show = false
            fetchTemplateList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }
    return {
        headers,
        param,
        table,
        fetchTemplateList,
        fetchTemplateTypeList,
        addTemplate,
        delTemplate
    }
}
