import axios from '@axios'

export default {
    namespaced: true, state: {}, getters: {}, mutations: {}, actions: {

        // 短信模板
        fetchTemplateList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/content/sms/template/page/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchTemplateTypeList(ctx) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/content/sms/template/type/list`, { }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addTemplate(ctx, item) {
            return new Promise((resolve, reject) => {
                axios.post(`/admin-company/v1/content/sms/template`, { type: item.type, content: item.content }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        editTemplate(ctx, item) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/content/sms/template/${item.id}`, { type: item.type, content: item.content }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        delTemplate(ctx, item) {
            return new Promise((resolve, reject) => {
                axios.delete(`/admin-company/v1/content/sms/template/${item.id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        // 短信发送记录
        fetchSendRecordList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/content/sms/send/record/page/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: {state: queryParams.options.state, key: queryParams.key} }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        completeState(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/content/sms/send/record/complete`, { ids: ids }).then(response => resolve(response)).catch(error => reject(error))
            })
        },

    },
}
