<template>
    <div id='template-list'>
        <v-dialog v-model="table.dialogAddTemplate.show" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">新增模板</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select :items="table.typeList" v-model="table.dialogAddTemplate.data.type" item-text="name" item-value="type"
                                          label="模板类型" hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field v-model="table.dialogAddTemplate.data.content" label="模板内容" dense hint="请输入模板内容"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined
                           @click="table.dialogAddTemplate = {show: false, data: {id: ''}}"> 取消
                    </v-btn>
                    <v-btn color="success"
                           @click="addTemplate"> 提交
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="table.dialogDelTemplate.show" max-width="500px">
            <v-card>
                <v-card-title> 确认删除？</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click="table.dialogDelTemplate = {show: false, id: ''}"> 取消</v-btn>
                    <v-btn color="success" @click="delTemplate"> 确定</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="table.snack.show" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snack.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snack.show = false"> 关闭</v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-0'>
                <v-text-field v-model='param.query' class='user-search me-3 mb-4' dense hide-details outlined
                              placeholder='搜索模板' clearable></v-text-field>
                <v-spacer></v-spacer>
                <div class='d-flex align-center flex-wrap'>
                    <v-btn class='mb-4 me-3' color='primary' @click="table.dialogAddTemplate.show = true">
                        <v-icon>{{ icons.mdiPlus }}</v-icon>
                        <span>新增模板</span>
                    </v-btn>
                </div>
            </v-card-text>
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                          :server-items-length='table.total' show-select>
                <!-- actions -->
                <template #[`item.actions`]='{ item }'>
                    <v-row>
                        <v-col>
                            <v-btn small @click="editTemplate(item)" color="info" dark>
                                <v-icon dark left> {{ icons.mdiPlaylistEdit }}</v-icon>
                                编辑
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn small @click="delTemplateDialog(item)" color="error" dark>
                                <v-icon dark left> {{ icons.mdiDeleteOff }}</v-icon>
                                删除
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import {mdiPlaylistEdit, mdiDeleteOff,} from '@mdi/js'
import store from '@/store'
import {onUnmounted, ref} from '@vue/composition-api'
import smsStoreModule from '../smsStoreModule'
import templateList from './contentSmsTemplate'

export default {
    components: {}, setup() {
        const CONTENT_SMS_STORE_MODULE_NAME = 'content-sms-template-list'
        // Register module
        if (!store.hasModule(CONTENT_SMS_STORE_MODULE_NAME)) store.registerModule(CONTENT_SMS_STORE_MODULE_NAME, smsStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CONTENT_SMS_STORE_MODULE_NAME)) store.unregisterModule(CONTENT_SMS_STORE_MODULE_NAME)
        })
        const {headers, param, table, fetchTemplateList, fetchTemplateTypeList, addTemplate, delTemplate} = templateList()

        fetchTemplateList()
        fetchTemplateTypeList()

        const editTemplate = (item) => {
            table.value.dialogAddTemplate = {
                show: true,
                data: item
            }
        }

        const delTemplateDialog = (item) => {
            table.value.dialogDelTemplate = {
                show: true,
                id: item.id
            }
        }

        return {
            headers,
            param,
            table,
            fetchTemplateList,
            fetchTemplateTypeList,
            addTemplate,
            editTemplate,
            delTemplateDialog,
            delTemplate,
            // icons
            icons: {mdiPlaylistEdit, mdiDeleteOff},
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
